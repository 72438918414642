import styles from './styles.module.scss';
import { Helmet } from "react-helmet";

export function News() {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>News - July-01</title>
                <meta name="description" content="Testing share content page News July 1" />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://sthorm.io/" />
                <meta property="og:type" content="website" />
                <meta name="title" property="og:title" content="Sthorm - Find The Others" />
                <meta property="og:site_name" content="Sthorm" />
                <meta
                    property="og:description"
                    content="Leveraging blockchain technologies for the advancement and dissemination of social and environmentally applied sciences."
                />
                <meta
                    property="og:image"
                    content="/images/sthorm/ogimage.jpg"
                />

                {/* <!-- Twitter Meta Tags --> */}
                <meta property="twitter:url" content="https://sthorm.io/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Sthorm - Find The Others" />
                <meta property="og:site_name" content="Sthorm" />
                <meta
                    name="twitter:description"
                    content="Leveraging blockchain technologies for the advancement and dissemination of social and environmentally applied sciences."
                />
                <meta
                    name="twitter:image"
                    content="/images/sthorm/ogimage.jpg"
                />
            </Helmet>
            {
                new Array(8).fill(0).map((_, index) => (
                    <img
                        className={styles.img}
                        src={`/images/news/sthorm-d-verse-${index + 1}.png`}
                        alt=""
                    />
                ))
            }
        </div >
    );
}