export function GeneralOnboarding() {
    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe style={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: 100
        }} src="https://v2-embednotion.com/STHORM-Onboarding-303177f319f441b8b212a087e9536a60" />
    )
}