import { Route, Routes, useLocation } from "react-router-dom";

import HomeContent from "../views/HomeContent";
import { AboutContent } from "../views/AboutContent";
import { News } from "../views/News";
import { July2Page } from "../views/News/July2";
import GeneralContent from "../views/GeneralContent/GeneralContent";
import { GeneralOnboarding } from "../views/GeneralOnboarding";

export default function Routing() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route exact path="/" element={<GeneralContent />}>
        <Route exact path="/" element={<HomeContent />} />
        <Route path="/about" element={<AboutContent />} />
        <Route path="/General_Onboarding" element={<GeneralOnboarding />} />
        <Route path="/news/july-1" element={<News />} />
        <Route path="/news/july-2" element={<July2Page />} />
      </Route>
    </Routes>
  );
}
