import './styles/styles.sass';
import { useState, useLayoutEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { Preloader } from './components/Preloader'
import ScrollToTop from './utils/ScrollToTop'

import ColorScheme from './utils/ColorScheme';
import Routing from './utils/Routing';

export default function App () {
  //////////////////////// Handling Preloader ///////////////////////////////////////
  const [loading, setLoading] = useState(true);
  ColorScheme();
  
  useLayoutEffect(() => {
    const currentRouter = window.location
    if (currentRouter.pathname === '/General_Onboarding') {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 7500)
    }
    
  }, [])

  return (
    <>
      {loading === false ? (
        <div className="App">
          <Router>
            <ScrollToTop>
              <Routing />
            </ScrollToTop> 
          </Router>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}